<template>
<div id="GalleryDetails">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>


<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Image Details
    </p>
  </div>
</div>  


<v-container fluid style="margin-top: -230px">
<v-row>
    <v-col cols="12" sm="12" md="6" class="my-5">

    <v-card class="my-5 elevation-10" height="100%">
    <v-form @submit.prevent="UpdateImage()">

    <v-card-title class="mb-5">
        <p class="headline">Edit Image</p>
    </v-card-title>
    <v-card-text class=" d-flex justify-center flex-column">
        <div>
        <!-- file upload -->
        <v-file-input solo v-model="files" color="primary" single-line label="Change Image"
        prepend-icon="mdi-google-photos" accept="image/*">
        </v-file-input>
        <v-text-field solo label="About The Picture..." prepend-icon="mdi-heart" 
            v-model.trim="Title" required></v-text-field>
        </div>
    </v-card-text>
    <v-card-actions class="px-5">
        <v-btn type="submit" :disabled="disbtn" class="elevation-10 white--text success"> Update </v-btn>
        <v-btn @click="$router.go(-1)" class="elevation-10 secondary">back</v-btn>
    </v-card-actions>

    <!-- show overlay when edit item -->
    <v-fade-transition>
    <v-overlay absolute :value="disbtn" opacity="0.2">
        <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
    </v-overlay>
    </v-fade-transition>

    </v-form>
    </v-card>

    </v-col>

    <v-col cols="12" sm="12" md="6" class="my-5">

    <v-card class="my-5 elevation-10" height="100%">
    <v-img :src="GalleryData.imageUrl" class="white--text align-end" style="height: 300px;" rel="preload">
        <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
            </v-row>
        </template>
        <div class="pa-1" style="background-color: rgba(0,0,0,0.4);" v-if="GalleryData.title != ''">
            <p class="d-flex align-start pt-5 pl-2">{{GalleryData.title}}</p>
        </div>
    </v-img>
    <v-card-actions class="d-flex align-center justify-center">
        <v-btn dark absolute @click="deleteDialog = true" :disabled="deleteFlag"
                bottom fab class="elevation-10 red darken-2">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </v-card-actions>

    <!-- show overlay when delete item -->
    <v-fade-transition>
    <v-overlay absolute :value="deleteFlag" opacity="0.2">
        <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
    </v-overlay>
    </v-fade-transition>
    
    </v-card>

    <!-- delete modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
    <v-card>
    <v-card-title class="headline">Confirm Delete?</v-card-title>
    <v-card-text>
        This action can't be undone!
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="DeleteImage()">Delete</v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <!-- delete dialog ends -->

    </v-col>
</v-row>
</v-container>
</div>
</template>

<script>
// imports
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {
    
    name: 'GalleryDetails',

    data(){return{
        files: null,disbtn: false,notificationToggle: false,notificationMsg: 'hai',
        notificationType: 'hui',Title: '',deleteDialog: false,deleteFlag: false,
    }},

    mounted(){this.Title = this.GalleryData.title;},

    methods: {

        async UpdateImage(){

            if(!this.BlankValidation()){return false;}this.disbtn = true;

            let TitleGal = this.Title != '' ?  this.Title : '';

            await firebase.firestore().collection("gallery").where("galleryslug", "==", this.GallerySlug).get().then(
            (doc) => {
                // update all datas(sorry for cramping it up)
                firebase.firestore().collection("gallery").doc(doc.docs[0].id).update({
                    "title": TitleGal,
                }).then((succ) => {
                    // image upload
                    let imageFile;let imageFileName;let ext;let imageUrl;let task;
                    let key = doc.docs[0].id;

                    // check if image exists, run Final
                    if(this.files == null) { return this.Final(); }
                    // actual add file
                    imageFile = this.files;
                    // get filename
                    imageFileName = imageFile.name;
                    // get extention of filename
                    ext = imageFileName.slice(imageFileName.lastIndexOf('.'));
                    // get a reference to store image in firebase storage
                    let storageRef = firebase.storage().ref('gallery/' + key + ext);
                    // upload image
                    storageRef.put(imageFile).then((snapshot) => {
                        // get download url and update db
                        snapshot.ref.getDownloadURL().then((downloadURL) => {
                            firebase.firestore().collection('gallery').doc(key).update({ imageUrl: downloadURL})
                            .then((res) => { this.Final(); });
                        });
                    }).catch((err) => {
                        // spit out error
                        this.disbtn = false;
                        this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
                    });
                    
                }).catch((err) => {
                    // spit out error
                    this.disbtn = false;
                    this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
                }); 

            }).catch((err) => {
                // spit out error
                this.disbtn = false;
                this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
            }); 
            
        },

        async DeleteImage(){
            this.deleteDialog = false;this.deleteFlag = true;
            // get image url
            let url
            if(this.GalleryData.imageUrl){
                url = this.GalleryData.imageUrl.toString();
            }
            // get document where slug equals selected one
            await firebase.firestore().collection("gallery")
            .where("galleryslug", "==", this.GalleryData.galleryslug).get().then(
            (doc) => { if(doc.docs[0].id){
                // update all datas(sorry for cramping it up)
                firebase.firestore().collection("gallery").doc(doc.docs[0].id).delete()
                .then(
                (result) => { 
                    if(url){
                        firebase.storage().refFromURL(url).delete().then((succ) => {this.deleteFlag = false;this.$router.push("/galleryhome");});
                    }else{this.deleteFlag = false;this.$router.push("/galleryhome");}
                }
                );}       
            }).catch((err) => {
                // spit out error
                this.disbtn = false;this.deleteFlag = false;
                this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
            }); ; 
        },

        // run after all
        Final(){
            this.notificationService("Image Updated SuccessFully!", "success");
            this.disbtn = false;   
            this.$store.dispatch('getGallery');
            this.files = null;
        },

        // check if all fileds are empty   
        BlankValidation(){
            if(this.files){
              if(this.files.size / 1024 >= 2048){
                  this.notificationService("File Should be Under 2MB!", "red darken-1");
                  return false;
              }else{
                  return true;
              }
            }else{
                if(!this.files && this.Title == ''){
                    this.notificationService("Atleast One Field Should Have Value!", "red darken-1");return false}
                else{return true}
            }
        },

        // notification service
        notificationService(msg, type){
            this.notificationType = type;
            this.notificationMsg = msg;
            this.notificationToggle = true;
        },

    },

    computed: {

        GallerySlug(){ 
          return this.$route.params.galleryslug != null ? this.$route.params.galleryslug : null;
        },

        GalleryData(){
            if(this.GallerySlug != null) {
                let data =  this.$store.getters.GetGalleryWithSlug(this.GallerySlug);
                return data.length != 0 ? data[0] : this.$router.push("/galleryhome");
            }else{ return this.$router.push("/galleryhome"); }           
        }
        
    }

}
</script>

<style scoped>

</style>